<template>
  <!-- <TabMenu :model="items" />
  <router-view /> -->
  <div class="card mx-1">
    <div class="flex justify-content-between">
      <!-- Datetime selector -->
      <div class="flex gap-2 align-items-center">
        <Dropdown v-model="selectedMode" :options="modes" optionLabel="label" optionValue="value" optionDisabled="disabled" @change="updateToToday" />
        <Calendar v-model="startDate" :minDate="dayjs(new Date()).subtract(1, 'month').toDate()" :maxDate="new Date()" :showIcon="true" dateFormat="yy-mm-dd" />
        <strong v-if="selectedMode == 'range'"> - </strong>
        <Calendar v-if="selectedMode == 'range'" v-model="endDate" :minDate="startDate" :maxDate="new Date()" :showIcon="true" dateFormat="yy-mm-dd" />
      </div>
      <!-- 店號顯示 -->
      <Button class="p-button-text p-button-plain">
        <svg width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="1.5"><path d="M20.485 3h-3.992l.5 5s1 1 2.5 1a3.23 3.23 0 0 0 2.139-.806a.503.503 0 0 0 .15-.465L21.076 3.5a.6.6 0 0 0-.591-.5Z"/><path d="m16.493 3l.5 5s-1 1-2.5 1s-2.5-1-2.5-1V3h4.5Z"/><path d="M11.993 3v5s-1 1-2.5 1s-2.5-1-2.5-1l.5-5h4.5Z"/><path d="M7.493 3H3.502a.6.6 0 0 0-.592.501L2.205 7.73a.504.504 0 0 0 .15.465c.328.29 1.061.806 2.138.806c1.5 0 2.5-1 2.5-1l.5-5Z"/><path d="M3 9v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V9"/><path stroke-miterlimit="16" d="M14.833 21v-6a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v6"/></g></svg>
        <span class="ml-2 text-bold">{{ shopId }}</span>
      </Button>
    </div>
    <TabMenu class="mt-4" :model="items" />
    <router-view v-slot="{ Component }" :start-date="startDate" :end-date="endDate" :mode="selectedMode">
      <component :is="Component"></component>
    </router-view>
  </div>

</template>

<script setup>
import { ref, computed, watch } from 'vue'
import dayjs from 'dayjs'

const selectedMode = ref("single")
const modes = ref([
  { label: '單日', value: 'single' },
  { label: '區間', value: 'range' }
])
const startDate = ref(new Date())
const endDate = ref(new Date())
const items = ref([
  {
    label: '茶葉使用',
    // icon: 'pi pi-wrench',
    to: '/overview/manufacture'
  },
  {
    label: '茶葉報廢',
    // icon: 'pi pi-fw pi-file-excel',
    to: '/overview/scrap'
  },
  {
    label: '半成品製作',
    to: '/overview/toppings-manu',
    // disabled: true
  },
  {
    label: '半成品報廢',
    to: '/overview/toppings-scrap',
    // disabled: true
  }
])

const shopId = computed(() => {
  return localStorage.getItem('shop_id')
})

watch(startDate, (newVal, oldVal) => {
  if (dayjs(endDate.value).isBefore(dayjs(startDate.value))) {
    endDate.value = startDate.value
  }
})

const updateToToday = () => {
  startDate.value = new Date()
  endDate.value = new Date()
}
</script>

<style lang="scss">
.p-column-header-content {
  justify-content: center;
}

.p-calendar {
  .p-button {
    background: var(--purpply-secondary-color);
    border: 1px solid var(--purpply-secondary-color);

    &:hover {
      background: var(--purpply-primary-color);
      border-color: var(--purpply-primary-color);
    }
  }
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #ffffff;
    border-color: var(--purpply-secondary-color);
    color: var(--purpply-secondary-color);
}
</style>